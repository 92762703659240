import { MetaData } from "./content.types";

export function getImageFromMetaData(metaData?: Partial<MetaData> | null) {
  if (metaData?.staticImage) {
    return metaData.staticImage.url;
  }

  if (metaData?.imageUrl) {
    return metaData.imageUrl;
  }

  if (!metaData?.dynamicImageTitle) {
    return null;
  }

  let image;
  const title = encodeURIComponent(metaData.dynamicImageTitle);
  const subtitle =
    metaData.dynamicImageSubtitle &&
    encodeURIComponent(metaData.dynamicImageSubtitle);
  const illustrationUrl =
    metaData.dynamicImageIllustration &&
    encodeURIComponent(metaData.dynamicImageIllustration.url);
  image = `https://profy-social-card.netlify.app/.netlify/functions/create-card/?title=${title}`;
  if (subtitle) {
    image += `&subtitle=${subtitle}`;
  }
  if (illustrationUrl) {
    image += `&illustrationUrl=${illustrationUrl}`;
  }
  return image;
}
