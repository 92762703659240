import Head from "next/head";
import { useRouter } from "next/router";
import getPath from "@lib/getPath";
import Header from "@components/header";
import type { MetaData } from "@lib/content.types";
import { useLayoutEffect, useState } from "react";
import { getImageFromMetaData } from "@lib/getImageFromMetaData";
import { Meta } from "./meta";

type LayoutProps = {
  metaData?: MetaData | null;
  className?: string;
};

function ScrollDepthOverlay() {
  const [documentHeight, setDocumentHeight] = useState<number | null>(null);
  const steps = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];

  useLayoutEffect(() => {
    setDocumentHeight(document.body.getBoundingClientRect().height);
  }, []);

  if (!documentHeight) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: documentHeight,
        width: "40px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {steps.map((depth) => (
        <div
          style={{
            width: "100%",
            height: "10%",
            background: `rgba(120, ${(depth / 100) * 255}, 255, 0.8)`,
          }}
          key={depth}
        >
          {depth}%
        </div>
      ))}
    </div>
  );
}

const defaultTitle = "Profy.dev | Become a professional React developer";
const defaultDescription =
  "Learn the skills and workflows of the pros. Work on projects like in a real team. Build an outstanding portfolio for your job hunt";

const Layout: React.FC<LayoutProps> = ({
  metaData,
  className = "",
  children,
}) => {
  const router = useRouter();
  const title = metaData?.title || defaultTitle;
  const description = metaData?.description || defaultDescription;

  const url = `https://profy.dev${getPath(router.asPath)}`;

  const image = getImageFromMetaData(metaData);
  const meta = [
    {
      name: "description",
      content: description,
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:url",
      content: url,
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:creator",
      content: "profydev",
    },
    {
      name: "twitter:site",
      content: "profydev",
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:description",
      content: description,
    },
  ];

  if (image) {
    meta.push(
      {
        property: "og:image",
        content: image,
      },
      {
        name: "twitter:image",
        content: image,
      }
    );
  }

  return (
    <div className={`bg-gray-50 min-h-screen ${className}`}>
      {router.query["scroll-depth"] && <ScrollDepthOverlay />}
      <Meta metaData={metaData} />
      <Header />
      {children}
    </div>
  );
};

export default Layout;
