import Head from "next/head";
import { useRouter } from "next/router";
import getPath from "@lib/getPath";
import Header from "@components/header";
import type { MetaData } from "@lib/content.types";
import { getImageFromMetaData } from "@lib/getImageFromMetaData";

type MetaProps = {
  metaData?: Partial<MetaData> | null;
  className?: string;
};

const defaultTitle = "React Job Simulator by Profy.dev";
const defaultDescription =
  "Become a job-ready React developer by working on a production grade project. Join the next cohort of the React Job Simulator.";

export const Meta: React.FC<MetaProps> = ({ metaData }) => {
  const router = useRouter();
  const title = metaData?.title || defaultTitle;
  const description = metaData?.description || defaultDescription;

  const url = `https://profy.dev${getPath(router.asPath)}`;

  const image = getImageFromMetaData(metaData);
  const meta = [
    {
      name: "description",
      content: description,
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:url",
      content: url,
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:creator",
      content: "profydev",
    },
    {
      name: "twitter:site",
      content: "profydev",
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:description",
      content: description,
    },
  ];

  if (image) {
    meta.push(
      {
        property: "og:image",
        content: image,
      },
      {
        name: "twitter:image",
        content: image,
      }
    );
  }

  return (
    <Head>
      <title>{title}</title>
      {meta.map((props) => (
        <meta key={props.name || props.property} {...props} />
      ))}
      <link rel="canonical" href={url} />
    </Head>
  );
};
