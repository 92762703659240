import { useRouter } from "next/router";
import Link from "next/link";
import Icon, { IconType } from "@components/icon";
import { useAnalytics } from "@components/analytics";
import UserMenu from "./user-menu";

const Header: React.FC = () => {
  const { trackEvent } = useAnalytics();
  const router = useRouter();
  const showUserMenu = router.asPath.startsWith("/project");

  return (
    <header className="bg-gray-900 sticky top-0 z-50">
      {/* <PromotionBanner /> */}
      <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-4">
        <Link href="/">
          <a
            className="z-10 hidden xs:block"
            onClick={() => trackEvent("Click", "Click Header Logo")}
          >
            <Icon className="h-8 mr-4 hidden sm:block" type={IconType.Logo} />
            <Icon className="h-8 mr-4 sm:hidden" type={IconType.LogoSm} />
          </a>
        </Link>
        <nav
          className="relative flex items-center sm:justify-center sm:absolute sm:left-0 sm:w-full"
          aria-label="Global"
        >
          <Link href={router.pathname === "/" ? "/#membership" : "/"}>
            <a
              className="inline-flex items-center px-3.5 py-2 border border-transparent leading-4 font-medium whitespace-nowrap rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  sm:text-md"
              onClick={() => trackEvent("Click", "Click Header CTA")}
            >
              React Job Simulator
            </a>
          </Link>
        </nav>

        <div className="flex space-x-3 text-gray-100 relative">
          <a className="text-sm" href="/blog">
            Blog
          </a>

          {showUserMenu && <UserMenu />}
        </div>
      </div>
    </header>
  );
};

export default Header;
