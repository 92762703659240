import { useCallback, useState } from "react";
import Link from "next/link";
import { signOut, useSession } from "next-auth/client";
import type { User } from "@lib/data.types";
import type { MouseEvent } from "react";

const UserMenu = () => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [session] = useSession();
  const user = session?.user as User;
  const isPremiumUser = !!user?.membership;

  const onClickOutside = useCallback(() => {
    setUserMenuOpen(false);
    document.body.removeEventListener("click", onClickOutside);
  }, []);

  const toggleUserMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setUserMenuOpen((current) => {
      if (current) {
        document.body.removeEventListener("click", onClickOutside);
      } else {
        document.body.addEventListener("click", onClickOutside);
      }
      return !current;
    });
  };

  return (
    <>
      {user && (
        <div className="ml-3 relative">
          <div>
            <button
              type="button"
              className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              onClick={toggleUserMenu}
            >
              <span className="sr-only">Open user menu</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-6 w-6 ${
                  isPremiumUser ? "text-amber-400" : "text-white"
                }`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>

          {userMenuOpen && (
            <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg py-1 bg-white text-center text-gray-700 z-50">
              <div className="block px-4 py-2 text-sm text-gray-400">
                {isPremiumUser
                  ? "You're a Premium Member"
                  : "You're a Free Member"}
              </div>
              <div className="w-full h-0 border border-b-0 border-gray-200" />
              {isPremiumUser && (
                <>
                  <Link href="/project/react-job-simulator/welcome">
                    <a className="block px-4 py-2 text-sm cursor-pointer">
                      Project
                    </a>
                  </Link>
                  <Link href="/project/react-job-simulator/invite-to-designs">
                    <a className="block px-4 py-2 text-sm cursor-pointer">
                      Invite To Designs
                    </a>
                  </Link>
                  <Link href="/project/react-job-simulator/board">
                    <a className="block px-4 py-2 text-sm cursor-pointer">
                      Tasks On Kanban Board
                    </a>
                  </Link>
                  <Link href="/project/react-job-simulator/access-token">
                    <a className="block px-4 py-2 text-sm cursor-pointer">
                      API Access Token
                    </a>
                  </Link>
                </>
              )}
              <div className="w-full h-0 border border-b-0 border-gray-200" />
              <div
                className="block px-4 py-2 text-sm cursor-pointer"
                tabIndex={-1}
                role="button"
                onClick={() => signOut({ callbackUrl: "/" })}
              >
                Sign out
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UserMenu;
